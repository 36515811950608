<template>
    <div class="content  v-scroll">
        <ul v-if="reports">
            <li v-for="item in reports">
                <a href="#" :title="item.title" @click="openReport(item.id,item.title )">
                    {{ item.title.length > 30 ? item.title.substring(0,30) + '...' : item.title}}
                </a>
                <el-button size="medium" class="float-right d-none d-sm-inline-block" @click="openReport(item.id, item.title)">
                    <i class="fas fa-edit"></i> Modifier
                </el-button>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {

        props:[
            'reports'
        ],

        data() {
            return {

                isLoading: true,
            }
        },

        mounted() {

            this.isLoading = false;

        },

        methods:{


            _loadProducts(){

                this.$parent.$parent.$data.isLoading = true;

                // load store products, so we can load theme before next route: /report

                axios

                    .get('/products/' + this.$store.state.storeData.resellerType)

                    .then( response => {

                        // save products list for this reseller type
                        this.$store.commit('saveProductList', response.data.data);

                        // send loaded store data to the next route: /report
                        this.$router.push({
                            name:"report",
                            params: {
                                storeData: this.storeData
                            }
                        });
                    })

                    .catch( error => {

                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });

                    })

                    .finally(() =>{
                        //this.$parent.$parent.$data.isLoading = false;
                    });
            },

            _loadReport(id){

                // function to load existing draft from server

                this.$parent.$parent.$data.isLoading = true;

                axios

                    .get('/report/' + id)

                    .then( response => {

                        if (response.data.status == 'success'){

                            // load store data and save it in the vuex store
                            this.$store.commit('saveStore', response.data.store);

                            // load report data and save it in the vuex store
                            this.$store.commit('saveDraft', response.data.report);

                            // load draftId data and save it in the vuex store,
                            // to allow update mode
                            this.$store.commit('saveDraftId', id);

                            // check for store products, then go to the next route: /report
                            this._loadProducts();
                        }
                    })

                    .catch( error => {

                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });

                    })

                    .finally(() =>{
                        //this.$parent.$parent.$data.isLoading = false;
                    });

            },

            openReport(id, title){

                 // On click 'Edit Draft' we load  draft data from server

                 this.$confirm(`êtes vous sûr de vouloir modifier le brouillon:  ${title} ?`, '', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Annuler',
                    type: 'warning'

                }).then(()=>{
                    // If confirmed we load draft report by its ID
                     this._loadReport(id)
                 }).catch(()=>{
                     //console.log('open drat aborted!')

                 });

            }
        }
    }

</script>

<style lang="scss">

    /*********************************************
       Styles
   *********************************************/

    .app-container{

        header{

            .notices{

                background-color: #f3f3f3;
                border: 1px solid #e5e5e5;
                margin-top: 15px;

                a {
                    color:black;
                    font-size:.8em;
                }

                h4{
                    color:#dc2e35;
                    font-size:1em;
                }

                ul {

                    padding-left: 20px;

                    li{
                        clear: both;
                        line-height: 30px;
                    }
                }

                .content{
                    height:90px;
                }

                .el-button{
                    background-color:transparent;
                    padding:0;
                    margin:0;
                    line-height: 30px;
                }


            }
        }
    }

</style>
