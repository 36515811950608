<template>
    <div class="row no-gutters align-items-center">
        <div class="col-2 col-sm-3">
            <img src="images/v-pattern.jpg" class="img-fluid img-pattern">
        </div>
        <div class="col-10 col-sm-9 text-center">
            <a href="javascript:void(0)" @click="gotoHome()">
                <img src="images/app-logo.png" class="img-fluid logo mt-2 mt-sm-0 mx-0 mx-sm-2">
            </a>
        </div>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                isLoading: true,
            }
        },

        mounted() {
            this.isLoading = false
        },

        methods:{
            gotoHome(){
                this.$store.commit('clearAll');
                this.$router.push('home');
            }
        }

    }

</script>

<style lang="scss">

    /*********************************************
       Styles
   *********************************************/


</style>
