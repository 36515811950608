<template>
    <div class="bg-color bg-pattern">
        <!-- Start: Login Form -->
        <el-form ref="loginForm" :model="loginForm" :rules="rules"
                 v-loading="isLoading"
                 status-icon
                 @submit.native.prevent
        >
            <div class="container h-100" id="login-form">
                <div class="row align-items-center h-100">
                    <div class="col-sm-10 col-md-8  col-lg-8 mx-auto text-center">
                        <el-row>
                            <el-col :span="24" class="mb-5 mb-3">
                                <img src="images/logo.png" alt="Siera logo" class="img-fluid login-logo">
                            </el-col>

                            <el-col :span="24" class="my-3">
                                <el-form-item prop="email">
                                    <el-input placeholder="Login" size="medium" v-model="loginForm.email"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" class="my-3">
                                <el-form-item prop="password">
                                    <el-input placeholder="Mot de passe" size="medium" v-model="loginForm.password"
                                              show-password></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" class="p-3 my-3 text-left remember-me">
                                <el-form-item prop="remember">
                                    <el-switch
                                        style="display: block"
                                        active-color="#303d41"
                                        inactive-color="white"
                                        active-text="Se souvenir de moi"
                                        inactive-text=""
                                        active-value="1"
                                        inactive-value="0"
                                        v-model="loginForm.remember"
                                    >
                                    </el-switch>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24" class="my-3">
                                <el-form-item>
                                    <el-button type="info" @click="login()">Connexion</el-button>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </div>
                </div>
            </div>
        </el-form>
        <!-- End: Login Form -->
    </div>
</template>

<script>

    export default {

        data() {
            return {
                isLoading: false,
                loginForm: {
                    email: '',
                    password: '',
                    remember: true
                },
                rules: {
                    email: [
                        {required: true, message: "Merci d'entrer votre login", trigger: 'change'},
                        {max: 50, message: 'le Login ne doit pas dépasser 50 caractères!', trigger: 'blur'},
                        {min: 3, message: 'le Login est trop court!', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: "Merci d'entrer votre mot de passe", trigger: 'change'},
                        {max: 50, message: 'le mot de passe ne doit pas dépasser 50 caractères!', trigger: 'blur'},
                        {min: 3, message: 'le mot de passe est trop court!', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted() {
            console.log('Login Component mounted.')
        },
        methods: {
            login() {
                this.isLoading = true;
                this.$refs['loginForm'].validate((valid) => {
                    if (valid) {
                        this.$auth.login({
                            params: this.loginForm,
                            success: function () {
                                this.isLoading = false;
                                // Navigate to Home Page
                                this.$router.push("home");

                            },
                            error: function (resp) {
                                this.isLoading = false;
                                let error = resp.response.data.msg;
                                this.$alert(error, '', {
                                    confirmButtonText: 'OK',
                                    type: 'error',
                                    center: true,
                                    dangerouslyUseHTMLString: true
                                });
                            },
                            //rememberMe: this.loginForm.remember,
                            rememberMe: true,
                            //redirect: '/home',
                            //fetchUser:true
                        })
                    } else {
                        this.isLoading = false;
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="scss">

    /*********************************************
        Login form
    *********************************************/

    .el-loading-mask {
        height: 100%;
    }

    .el-loading-spinner {
        .path {
            stroke: #dc2e35 !important;
        }
    }

    .el-button--primary{
        background-color:#dc2e35!important;;
        border-color:red!important;
    }

    #login-form {
        padding-bottom: 15px;

        .el-switch {
            &.is-checked {
                .el-switch__core {
                    &:after {
                        background-color: white;
                    }
                }
            }
        }

        .el-input--medium {
            .el-input__inner {
                height: 60px;
                line-height: 60px;
                border-radius: 30px;
            }

        }

        .remember-me {
            border-radius: 30px;
            background-color: #dc2e35;
        }

        .el-switch__label * {
            color: #ffffff;
            font-size: 20px;
            &.is-active {

            }
            &.is-active {
                color: #303d41;
                font-weight: bold;
            }
        }

        .el-switch__core:after {
            background-color: #303e41;
        }

        .el-button--info, .wizard-btn {
            background-color: #364143 !important;
            height: 50px;
            font-size: 20px;
            min-width: 230px;
            border-radius: 30px !important;
            padding: 0;
            cursor: pointer;
        }

        .el-form-item {
            &.is-error {
                background-color: yellow;
                border-radius: 30px;
                padding: 2px;
            }
            &.is-required {

            }
        }
    }
</style>
