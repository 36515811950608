
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/*
Vue.component('SplashScreen', require('./components/SplashScreen.vue').default);
Vue.component('LoginForm', require('./components/LoginForm.vue').default);

Vue.component('HomeHeader', require('./components/HomeHeader.vue').default);
Vue.component('Home', require('./components/Home.vue').default);

Vue.component('SheetHeader', require('./components/SheetHeader.vue').default);
Vue.component('SheetForm', require('./components/SheetForm.vue').default);

Vue.component('UploadHeader', require('./components/UploadHeader.vue').default);
Vue.component('UploadForm', require('./components/UploadForm.vue').default);
*/



/* Animations */
require('vue2-animate/dist/vue2-animate.min.css');

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/* Routing */
import Routes from '@/js/routes.js';

/* Bootstrap */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue)

/* ElementUI */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

/* Vuex */
import Vuex from 'vuex';
Vue.use(Vuex);

/* Vuex Persisted State Plugin */
import createPersistedState from 'vuex-persistedstate'

/* Store */
const store = new Vuex.Store({
    state: {
        formData: [],
        storeData:{},
        productList:[],
        draftId:null,
        stepImage: 'images/step1.jpg'
    },
    mutations: {

        saveStepImage (state, stepName) {
            state.stepImage=  `images/${stepName}.jpg`;
            console.log('stepImage state saved.');
        },

        saveDraft (state, data) {
            state.formData =  data;
            console.log('draft state saved.');
        },
        saveDraftId (state, id) {
            state.draftId =  id;
            console.log('draft ID state saved.');
        },
        saveStore (state, data) {
            state.storeData =  data;
            console.log('store state saved.');
        },
        saveProductList (state, data) {
            state.productList =  data;
            console.log('productList state saved.');
        },
        clearReport (state){
            state.formData =  [];
            console.log('formData state cleared.');
        },
        clearProductList (state){
            state.productList = [];
            console.log('productList state cleared.');
        },
        clearAll (state){
            state.stepImage = 'images/step1.jpg';
            state.draftId = null;
            state.storeData = [];
            state.formData =  [];
            state.productList = [];
            console.log('vuex store cleared.');
        }
    },
    plugins: [createPersistedState()]
})

/* Axios */
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
axios.defaults.baseURL = document.head.querySelector('meta[name="api-base-url"]').content;;

/* App init */
import App from '@/js/layouts/App';

Vue.router = Routes

Vue.use(require('@websanova/vue-auth'), {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
});

App.router = Vue.router

const app = new Vue({
    el: '#app',
    // this will inject the store instance to all child components.
    store,
    render: h => h(App)
});


