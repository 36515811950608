<template>

    <el-form
        :model="reportForm"
        :rules="rules"
        ref="reportForm"
        status-icon
        @submit.native.prevent
    >
        <!-- Start: Form Section 1 -->
        <div class="form-section" v-if="stepProducts">

            <h6 class="header">On Store Availability</h6>

            <strong class="title">Standards de disponibilité et d'approvisionnement</strong>

            <p>Ces produits sont-ils disponibles dans le point de vente ?</p>

            <!-- Start: Generate Product List -->
            <el-row class="my-3" v-loading="isLoading">
                <template v-for="product in stepProducts">
                    <el-form-item :prop="'prod' + product.id ">
                        <el-col :span="10" :offset="2" :xs="{span:24, offset:0}" class="text-center text-sm-left">
                            <strong>{{product.name}}</strong>
                        </el-col>
                        <el-col :span="8" :offset="2" :xs="{span:24, offset:0}" class="text-center text-sm-right">
                            <el-radio-group v-model="reportForm['prod' + product.id ]" @change="update()">
                                <el-radio :label="1">Oui</el-radio>
                                <el-radio :label="0">Non</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-form-item>
                </template>
            </el-row>
            <!-- End: Generate Product List -->

            <!-- Start: Nombre total des produits disponibles-->
            <el-row class="my-3">

                <el-col :span="12" :xs="24">
                    <label class="text-left">Nombre total des produits disponibles</label>
                </el-col>

                <el-col :span="11" :offset="1" :xs="{span:24, offset:0}" class="text-center text-sm-right">
                    <el-form-item prop="osa_total_products">
                        <el-input-number :min="0" :max="stepProducts.length" class="w-100"
                                         v-model="reportForm.osa_total_products"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: Nombre total des produits disponibles-->

            <hr>

            <!-- Start: Score OSA-->
            <el-row class="my-3">
                <el-col :span="12">
                    <label class="score">Score OSA</label>
                </el-col>
                <el-col :span="9" :offset="3" class="text-center text-sm-right">
                    <el-input placeholder="0" disabled v-model="reportForm.osa_score"></el-input>
                </el-col>
            </el-row>
            <!-- End: Score OSA-->

            <!-- Start: Commentaire -->
            <el-row class="my-3">
                <el-col :span="24">
                    <el-form-item>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10}"
                            placeholder="Commentaire"
                            v-model="reportForm.osa_comment"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: Commentaire -->

        </div>
        <!-- End: Form Section 1 -->

        <!-- Start: Form Section 2 -->
        <div class="form-section">

            <h6 class="header">Shelf standard</h6>

            <strong class="title">Standards merchandising</strong>

            <!-- Start: ss_q1 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que l'un ou plusieurs des produits SIERA sont
                        visible(s) depuis l'entrée du point de vente ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="ss_q1">
                        <el-radio-group v-model="reportForm.ss_q1">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: ss_q1 -->

            <!-- Start: ss_q2 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que les produits SIERA sont sufisemment
                        placées dans les hotspots du point de vente ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="ss_q2">
                        <el-radio-group v-model="reportForm.ss_q2">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: ss_q2 -->

            <!-- Start: ss_q3 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que les produits SIERA sont mieux mis en
                        avant que les produits concurrents ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="ss_q3">
                        <el-radio-group v-model="reportForm.ss_q3">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: ss_q3 -->

            <!-- Start: ss_q4 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que l'on retrouve dans le point de vente
                        un ou plusieurs regroupements marque ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="ss_q4">
                        <el-radio-group v-model="reportForm.ss_q4">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: ss_q4 -->

            <!-- Start: Note globale des standards merchandising respectés -->
            <el-row class="my-3">

                <el-col :span="12" :xs="24">
                    <label class="text-left">Note globale des standards merchandising respectés</label>
                </el-col>

                <el-col :span="11" :offset="1" :xs="{span:24, offset:0}" class="text-center text-sm-right">
                    <el-form-item prop="ss_note">
                        <el-input-number :min="0" :max="maxSS" class="w-100"
                                         v-model="reportForm.ss_note"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
            <!-- End: Note globale des standards merchandising respectés -->

            <hr>

            <!-- Start: Score SS -->
            <el-row class="my-3">
                <el-col :span="12">
                    <label class="score">Score SS</label>
                </el-col>
                <el-col :span="9" :offset="3" class="text-center text-sm-right">
                    <el-input placeholder="0" disabled v-model="reportForm.ss_score"></el-input>
                </el-col>
            </el-row>
            <!-- End: Score SS -->

            <!-- Start: Commentaire SS -->
            <el-row class="my-3">
                <el-col :span="24">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 10}"
                        placeholder="Commentaire"
                        v-model="reportForm.ss_comment"
                    ></el-input>
                </el-col>
            </el-row>
            <!-- Start: Commentaire SS -->

        </div>
        <!-- End: Form Section 2 -->

        <!-- Start: Form Section 3 -->
        <div class="form-section">

            <h6 class="header">Share of shelf</h6>

            <strong class="title">Part d'espace attribué à SIERA </strong>

            <!-- Start: sos_q1 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Combien de pièces SIERA occupent l'espace physique
                        primaire du magasin ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="sos_q1">
                        <!--el-radio-group v-model="reportForm.sos_q1">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group-->
                        <el-input-number size="mini" v-model="reportForm.sos_q1" :min="0"
                                         @change="updateSOS()"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: sos_q1 -->

            <!-- Start: sos_q2 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Combien de pièces SIERA occupent l'espace physique
                        secondaire du magasin (Cave et/ou à l'étage)</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="sos_q2">
                        <!--el-radio-group v-model="reportForm.sos_q2">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group-->
                        <el-input-number size="mini" v-model="reportForm.sos_q2" :min="0"
                                         @change="updateSOS()"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: sos_q2 -->

            <!-- Start: sos_q3 -->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Combien de pièces de la gamme {{ gammes[stepIndex]}} (Siera &
                        concurrents) occupent l'espace principal et
                        secondaire du magasin</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="sos_q3">
                        <!--el-radio-group v-model="reportForm.sos_q3">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group-->
                        <el-input-number size="mini" v-model="reportForm.sos_q3" :min="1"
                                         @change="updateSOS_Q3()"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: sos_q3 -->


            <!-- Start: % part d'espace SIERA dans le magasin -->
            <el-row class="my-3">

                <el-col :span="12" :xs="24">
                    <label class="text-left">% part d'espace SIERA dans le magasin</label>
                </el-col>
                <el-col :span="11" :offset="1" :xs="{span:24, offset:0}" class="text-center text-sm-right">
                    <el-form-item prop="sos_note">
                        <el-input-number :min="0" class="w-100" v-model="reportForm.sos_note"
                                         disabled></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
            <!-- End: % part d'espace SIERA dans le magasin -->

            <hr>

            <!-- Start: Score SOS -->
            <el-row class="my-3">
                <el-col :span="12">
                    <label class="score">Score SOS</label>
                </el-col>
                <el-col :span="9" :offset="3" class="text-center text-sm-right">
                    <el-input placeholder="0" disabled v-model="reportForm.sos_score"></el-input>
                </el-col>
            </el-row>
            <!-- End: Score SOS -->

            <!-- Start: Commentaire SOS -->
            <el-row class="my-3">
                <el-col :span="24">
                    <el-form-item>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10}"
                            placeholder="Commentaire"
                            v-model="reportForm.sos_comment"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: Commentaire SOS -->

        </div>
        <!-- End: Form Section 3 -->

        <!-- Start: Form Section 4 -->
        <div class="form-section">

            <h6 class="header">Point of sales materials</h6>

            <strong class="title">PLV à tracker au niveau du point de vente</strong>

            <!-- Start: POSM Q1-->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que les POSM sont suffisemment placés sur
                        les produits SIERA dans le point de vente ?</label>
                </el-col>

                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="posm_q1">
                        <el-radio-group v-model="reportForm.posm_q1">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: POSM Q1-->

            <!-- Start: POSM Q2-->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que les POSM différencient suffisemment
                        les produits SIERA des produits concurrents ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="posm_q2">
                        <el-radio-group v-model="reportForm.posm_q2">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: POSM Q2-->

            <!-- Start: POSM Q3-->
            <el-row class="my-3">
                <el-col :span="12" :xs="24">
                    <label>Est-ce que les POSM sont suffisemment mis en
                        place dans les hotspots du point de vente ?</label>
                </el-col>
                <el-col :span="12" :xs="24" class="text-center text-sm-right">
                    <el-form-item prop="posm_q3">
                        <el-radio-group v-model="reportForm.posm_q3">
                            <el-radio v-for="item in radioList" :label="item.value" :key="item.value"
                                      @change="update()">{{item.label}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: POSM Q3-->


            <!-- Start: Note globale des standards de placement des POSM -->
            <el-row class="my-3">

                <el-col :span="12" :xs="24">
                    <label class="text-left">Note globale des standards de placement des POSM</label>
                </el-col>
                <el-col :span="11" :offset="1" :xs="{span:24, offset:0}" class="text-center text-sm-right">
                    <el-form-item prop="posm_note">
                        <el-input-number :min="0" :max="maxPOSM" class="w-100"
                                         v-model="reportForm.posm_note"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
            <!-- End: Note globale des standards de placement des POSM -->

            <hr>

            <!-- Start: Score POSM -->
            <el-row class="my-3">
                <el-col :span="12">
                    <label class="score">Score POSM</label>
                </el-col>
                <el-col :span="9" :offset="3" class="text-center text-sm-right">
                    <el-input placeholder="0" disabled v-model="reportForm.posm_score"></el-input>
                </el-col>
            </el-row>
            <!-- End: Score POSM -->

            <!-- Start: Commentaire POSM -->
            <el-row class="my-3">
                <el-col :span="24">
                    <el-form-item>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 10}"
                            placeholder="Commentaire"
                            v-model="reportForm.posm_comment"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- End: Commentaire POSM -->

        </div>
        <!-- End: Form Section 4 -->

        <!-- Start: Overall score -->
        <div class="overall-score" :class="{ 'd-none' : !$auth.user().is_admin }">
            <el-row>
                <el-col :span="12">
                    <label class="score">Overall Score</label>
                </el-col>
                <el-col :span="9" :offset="3" class="text-center text-sm-right">
                    <el-input placeholder="0" disabled v-model="overallScore"></el-input>
                </el-col>
            </el-row>
        </div>
        <!-- End: Overall score -->

        <div class="step-index">{{stepIndex+1}} / 4</div>

    </el-form>


</template>


<script>


    export default {

        props: [
            'stepIndex',
            'store'
        ],

        data() {

            let $this = this;

            let checkProductsCount = (rule, value, callback) => {
                if ($this.reportForm.osa_total_products != $this.getSelectedProductsCount()) {
                    return callback(new Error('Le nombre de produits indiqués ne correspond pas au total des produits choisis'));
                }
            };

            let checkSSNote = (rule, value, callback) => {
                if ($this.reportForm.ss_note != $this.getSelectedSSNote()) {
                    return callback(new Error('Le nombre de produits indiqués ne correspond pas à la note globale choisie'));
                }
            };

            let checkSOSNote = (rule, value, callback) => {
                if ($this.reportForm.sos_note > 100) {
                    return callback(new Error('Le pourcentage  indiqué est incorrect, merci de vérifier les quantités'));
                }
            };

            let checkPOSMNote = (rule, value, callback) => {
                if ($this.reportForm.posm_note != $this.getSelectedPOSMNote()) {
                    return callback(new Error('Le nombre de produits indiqués ne correspond pas à la note globale choisie'));
                }
            };

            // BUGFIX: radios not validated properly when its dynamic
            // load Products list for this step from vuex
            let stepProducts = [];
            let stepProductValues = null;
            if (!this.$store.state.productList) {

                this.$alert('Une erreur s\'est produite. veuillez réessayer plus tard', '', {
                    confirmButtonText: 'OK',
                    type: 'error',
                    center: true
                });

            } else {

                // trying to load products stored value from local vuex
                /*if (this.$store.state.formData[this.stepIndex]){
                    if(this.$store.state.formData[this.stepIndex].products){
                        stepProductValues = JSON.parse(this.$store.state.formData[this.stepIndex].products);
                    }
                }*/


                stepProducts = this.$store.state.productList[this.stepIndex];
            }

            let dynamicReportForm = {
                //
                osa_total_products: 0,
                osa_score: 0,
                osa_comment: "",
                //

                ss_q1: null,
                ss_q2: null,
                ss_q3: null,
                ss_q4: null,
                ss_q5: null,

                ss_note: 0,
                ss_score: 0,
                ss_comment: "",

                //

                sos_q1: null,
                sos_q2: null,
                sos_q3: null,

                sos_note: 0,
                sos_score: 0,
                sos_comment: "",

                //

                posm_q1: null,
                posm_q2: null,
                posm_q3: null,

                posm_note: 0,
                posm_score: 0,
                posm_comment: "",

                //
                overall_score: 0
            }

            // BUGFIX: radios not validated properly when its dynamic
            // Set default product values on data loaded
            for (let i = 0; i < stepProducts.length; i++) {
                var prod_key = 'prod' + stepProducts[i].id;
                dynamicReportForm[prod_key] = null;
            }

            return {

                canUpdateSOS_Q3:true,

                maxSS: 20,

                maxSOS: 100,

                maxPOSM: 15,

                overallScore: 0,

                isLoading: true,

                radioList: [],

                gammes: [
                    "Blanc",
                    "Brun",
                    "Cuisine",
                    "Lavage"
                ],

                stepProducts: stepProducts,

                reportForm: dynamicReportForm,

                rules: {

                    osa_total_products: [
                        {validator: checkProductsCount, trigger: 'change'}
                    ],

                    ss_q1: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    ss_q2: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    ss_q3: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    ss_q4: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],

                    ss_note: [
                        {validator: checkSSNote, trigger: 'change'}
                    ],

                    sos_q1: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    sos_q2: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    sos_q3: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],

                    sos_note: [
                        {validator: checkSOSNote, trigger: 'change'}
                    ],

                    posm_q1: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    posm_q2: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],
                    posm_q3: [
                        {required: true, message: "Merci de répondre à cette question!", trigger: 'change'},
                    ],

                    posm_note: [
                        {validator: checkPOSMNote, trigger: 'change'}
                    ],
                }
            }
        },


        created() {

            // BUGFIX: radios not validated properly when its dynamic
            // Set product validation rules
            for (let i = 0; i < this.stepProducts.length; i++) {
                var prod_key = 'prod' + this.stepProducts[i].id;
                //this.rules[prod_key] = [];
                this.rules[prod_key] = {
                    required: true,
                    message: "Merci d'indiquer la disponibilité du produit",
                    trigger: 'change',
                    type: 'number'
                };
            }
        },

        mounted() {

            // Populate 5 radio's groups
            for (let i = 0; i <= 5; i++) {
                this.radioList.push({value: i, label: i})
            }

            // load stored data from vuex
            if (this.$store.state.formData.length) {

                this.reportForm = this.$store.state.formData[this.stepIndex];

                // trying to load products stored value from local vuex
                if (this.$store.state.formData[this.stepIndex].products) {
                    let stepProductValues = JSON.parse(this.$store.state.formData[this.stepIndex].products);
                    for (let i = 0; i < this.stepProducts.length; i++) {
                        var prod_key = 'prod' + this.stepProducts[i].id;
                        this.reportForm[prod_key] = stepProductValues[this.stepProducts[i].id];
                    }
                }

                this.update();
            }

            this.isLoading = false;
        },

        computed: {},


        methods: {

            getSelectedProductsCount() {

                // Nombre total des produits selectionnés

                let maxProds = this.stepProducts.length;
                let osa_total_products = 0;
                for (let i = 0; i < maxProds; i++) {
                    let prodId = this.stepProducts [i].id;
                    let prodValue = parseInt(this.reportForm['prod' + prodId]);
                    if (!prodValue) prodValue = 0;
                    osa_total_products += prodValue;
                }
                return osa_total_products;
            },

            getSelectedSSNote() {

                return this.reportForm.ss_q1 + this.reportForm.ss_q2 + this.reportForm.ss_q3 + this.reportForm.ss_q4;
            },

            getSelectedSOSNote() {

                return this.reportForm.sos_q1 + this.reportForm.sos_q2 + this.reportForm.sos_q3
            },

            getSelectedPOSMNote() {

                return this.reportForm.posm_q1 + this.reportForm.posm_q2 + this.reportForm.posm_q3
            },


            updateSOS(){
                // update automatically  SOS Q3 if not touched yet
                if (this.canUpdateSOS_Q3)
                    this.reportForm.sos_q3 = this.reportForm.sos_q1 + this.reportForm.sos_q2

                // Share of shelf
                let sos_note = ((this.reportForm.sos_q1 * 2) + (this.reportForm.sos_q2 * 1)) / (this.reportForm.sos_q3 * 1.5);
                this.reportForm.sos_note = Math.ceil(sos_note * 100);

                //let sos_score = Math.ceil((sos_note / this.maxSOS) * 100);
                let sos_score = this.reportForm.sos_note;
                this.reportForm.sos_score = sos_score + ' %';
            },

            updateSOS_Q3(){
                this.canUpdateSOS_Q3 = false;
                this.update();
            },

            update() {

                // Nombre total des produits disponibles
                this.reportForm.osa_total_products = this.getSelectedProductsCount();

                // Score OSA %
                let maxProds = this.stepProducts.length;
                let osa_score = Math.ceil((this.reportForm.osa_total_products / maxProds) * 100);
                this.reportForm.osa_score = osa_score + ' %';

                // Shelf standard
                let ss_note = this.getSelectedSSNote();
                this.reportForm.ss_note = ss_note;

                let ss_score = Math.ceil((ss_note / this.maxSS) * 100);
                this.reportForm.ss_score = ss_score + ' %';


                // Share of shelf
                let sos_note = ((this.reportForm.sos_q1 * 2) + (this.reportForm.sos_q2 * 1)) / (this.reportForm.sos_q3 * 1.5);
                this.reportForm.sos_note = Math.ceil(sos_note * 100);

                //let sos_score = Math.ceil((sos_note / this.maxSOS) * 100);
                let sos_score = this.reportForm.sos_note;
                this.reportForm.sos_score = sos_score + ' %';

                // Point of sales materials
                let posm_note = this.getSelectedPOSMNote();
                this.reportForm.posm_note = posm_note;

                let posm_score = Math.ceil((posm_note / this.maxPOSM) * 100);
                this.reportForm.posm_score = posm_score + ' %';

                // Overall score
                let overall_score = Math.ceil(((osa_score * 2) + ss_score + (sos_score * 2) + posm_score) / 6);

                this.overallScore = overall_score + ' %';
                this.reportForm.overall_score = overall_score;

            }
        }
    }
</script>

<style lang="scss">

    /*********************************************
    App Form Content
    *********************************************/

    .step-index {
        position: relative;
        color: white;
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 100px;
        margin-bottom: -35px;
        padding-top: 20px;
    }

    #total-scoring {
        background-color: white;
        color: #dc2e35;
        border-radius: 5px;
        max-width: 280px;
        display: inline-block;
        margin: 0 auto;
        padding: 5px 15px;
        font-weight: bold;
        min-width: 160px;
    }

    .vue-form-wizard {

        .wizard-tab-content {

            min-height: 30px !important;
            margin-top: 40px;
            margin-bottom: 0px;
            padding: 0 !important;

            .form-section {

                padding: 15px;
                background-color: white;
                border-radius: 10px;
                margin-bottom: 20px;

                .header {
                    background-color: #dc2e35;
                    display: block;
                    width: 100%;
                    color: white;
                    line-height: 32px;
                    padding: 0px 10px;
                }

                .title {
                    border-bottom: 1px solid black;
                    display: block;
                    width: 100%;
                    line-height: 30px;
                    margin-bottom: 20px;
                }

                label {
                    &.score {
                        font-weight: bolder;
                        color: #dc2e35;
                    }
                }

                .el-radio__inner:hover {
                    border-color: #dc2e35;
                }

                .el-textarea__inner {
                    min-height: 160px !important;
                    height: auto !important;
                }


            }

            .overall-score {

                border-radius: 10px;
                padding: 5px 15px;
                line-height: 32px;
                margin-bottom: 15px;
                background-color: #364143 !important;

                label {
                    color: #dc2e35;
                    font-weight: bolder;
                    margin-bottom: 0 !important;
                    line-height: 40px;
                }
            }

            .el-form-item__error {

                margin-left: 0 !important;;
                text-align: left !important;;

            }

            .el-radio__label {

                padding-left: 5px;
                padding-right: 8px;
                vertical-align: top;
            }

            .el-input.is-disabled {
                .el-input__inner {
                    text-align: center;
                }
            }


        }
    }

    .wizard-btn {
        background-color: #364143 !important;
        height: 50px;
        font-size: 20px;
        min-width: 230px;
        border-radius: 30px !important;
        padding: 0;
    }


    /*********************************************
    Media Screen
    *********************************************/

    /* Medium devices (tablets, 768px and up)*/
    @media (min-width: 768px) {

        .form-section {
            .el-radio {
                margin-right: 10px !important;
            }
        }

        .el-col.el-col-12.el-col-xs-24 {
            label {
                font-size: 0.9em;
                text-align: justify;
                margin-right: 15px;
            }
        }

    }

    @media (min-width: 576px) {

        .form-section {
            .el-radio {
                margin-right: 1px !important;
            }
        }
    }

    @media (max-width: 448px) {

        .form-section {
            .el-radio {
                margin-right: 0 !important;
            }
        }
        .wizard-progress-with-circle {
            display: none;
        }
        .vue-form-wizard {
            .wizard-nav > li {
                margin-bottom: 30px;
            }
        }
    }


</style>
