<template>
    <header class="container-fluid px-0">
        <div class="w-100 position-absolute">
           <header-logout></header-logout>
        </div>
        <div class="container py-0 px-sm-0">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <header-logo></header-logo>
                </div>
                <div class="col-12 col-sm-8 mhy">
                    <div class="row align-items-center">
                        <div class="col-12 col-sm-8 offset-sm-4 px-0 py-0">

                            <div class="reference-wrap my-0 my-sm-5" @click="showImage()">
                                <img :src="$store.state.stepImage" class="img-fluid img-reference">
                            </div>

                            <vue-easy-lightbox
                                :visible="visible"
                                :imgs="image"
                                @hide="onImageHide"
                            ></vue-easy-lightbox>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

    import Vue from 'vue';

    import Lightbox from 'vue-easy-lightbox'
    Vue.use(Lightbox)

    Vue.component('header-logo', require('@/js/views/HeaderLogo.vue').default);
    Vue.component('header-logout', require('@/js/views/HeaderLogout.vue').default);

    export default {

        data() {
            return {
                image:'',
                visible:false,
                isLoading: true
            }
        },

        mounted() {
            this.isLoading = false
        },

        methods:{
            showImage() {

                this.image = this.$store.state.stepImage;
                this.visible = true;
            },
            onImageHide(){
                this.visible = false
            }
        }
    }

</script>

<style lang="scss">

    /*********************************************
       Report Header
   *********************************************/
    .reference-wrap{
        border: 8px solid #adadad;
        text-align: center;
        background-color: white;
    }

    .reference-wrap .img-reference{

    }

</style>
