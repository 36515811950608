<template>
    <header class="container-fluid px-0">
        <div class="w-100 position-absolute">
           <header-logout></header-logout>
        </div>
        <div class="container py-0 px-sm-0">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <header-logo></header-logo>
                </div>
                <div class="col-12 col-sm-8 ">
                    <div class="row align-items-center h-100">
                        <div class="col-12 px-0">
                            <div class="px-3 py-2 mr-0 mr-sm-3">
                                <div class="heading-sheet">
                                    <div class="container">
                                        <div class="row align-items-center h-100">
                                            <div class="col-12" v-if="store">
                                                <h4 class="text-uppercase" v-if="resellerTypeNames">Fiche reporting {{resellerTypeNames[store.resellerType-1]}}</h4>
                                                <span>
                                                Ville : <strong>{{store.cityName}}</strong><br>
                                                Magasin : <strong>{{store.storeName}}</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

    import Vue from 'vue';

    Vue.component('header-logo', require('@/js/views/HeaderLogo.vue').default);
    Vue.component('header-logout', require('@/js/views/HeaderLogout.vue').default);

    export default {

        data() {
            return {
                isLoading: true,
                store:null,
                resellerTypeNames : [
                    "Petit revendeur",
                    "Moyen revendeur",
                    "Grand revendeur",
                    "Hybrid revendeur"
                ]
            }
        },

        mounted() {

            // load store data from local
            if ( this.$store.state.storeData){

                this.store = this.$store.state.storeData;

            }else if (this.storeData){

                this.store = this.storeData;
            }

            this.isLoading = false
        }
    }

</script>

<style lang="scss">

    /*********************************************
       Styles
   *********************************************/
    .heading-sheet{
        h4{
            font-size:1em;
        }
    }

</style>
