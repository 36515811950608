<template>
    <div>

        <transition
            name="bounce"
            leave-active-class="fadeOut"
        >
            <div class="step-overlay" v-if="showStepIcon">
                <div class="step-num">
                    <div class="step-icon" :class="stepNames[currentStepIndex].icon">
                    </div>
                    <div class="step-title">
                        {{stepNames[currentStepIndex].name}}
                    </div>
                </div>
            </div>
        </transition>


        <!-- Start: App Wizard Forms -->
        <section class="app-container" v-loading="isLoading">

            <!-- Start:  Report Header -->
            <report-header ref="reportHeader"></report-header>
            <!-- End:  Report Header -->

            <!-- Start:  Report Sheet -->
            <nav class="nav sheet">
                <div class="container">
                    <div class="row align-items-center h-100">
                        <div class="col-12 col-sm-7" v-if="store">
                            <h4 class="text-uppercase" v-if="resellerTypeNames">Fiche reporting {{resellerTypeNames[store.resellerType-1]}}</h4>
                            <span>
                            Ville : <strong>{{store.cityName}}</strong><br>
                            Magasin : <strong>{{store.storeName}}</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </nav>
            <!-- End:  Report Sheet -->

            <!-- Start:  Report Content -->
            <main id="app-content" class="container-fluid h-100 app-container py-3 py-sm-0">
                <div class="container">
                    <!-- Start: App Wizard -->
                    <div class="row ">

                        <form-wizard
                            title=""
                            subtitle=""
                            color=""
                            error-color="yellow"
                            shape="circle"
                            back-button-text="Précédent"
                            next-button-text="Suivant"
                            finish-button-text="Valider"
                            step-size="lg"
                            :validateOnBack="false"
                            @on-validate="onValidate"
                            @on-complete="onComplete"
                            @on-change="onChange"

                        >
                            <tab-content title="Frigo" icon="icon-frigo" :before-change="()=>validateForm('step1')">
                                <report-form ref="step1" :stepIndex="0"></report-form>
                            </tab-content>

                            <tab-content title="Télévision" icon="icon-tv" :before-change="()=>validateForm('step2')" >
                                <report-form ref="step2" :stepIndex="1"></report-form>
                            </tab-content>

                            <tab-content title="Cuisiniere" icon="icon-four" :before-change="()=>validateForm('step3')">
                                <report-form ref="step3" :stepIndex="2"></report-form>
                            </tab-content>

                            <tab-content title="Machine à laver" icon="icon-mal" :before-change="()=>validateForm('step4')">
                                <report-form ref="step4" :stepIndex="3"></report-form>
                            </tab-content>

                        </form-wizard>


                    </div>
                    <!-- End: App Wizard -->
                </div>
            </main>
            <!-- End:  Report Content -->

        </section>
        <!-- End: App Wizard Forms -->
    </div>
</template>

<script>

    import Vue from 'vue';

    import {isMobile} from 'mobile-device-detect';

    import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import { Message } from 'element-ui';

    Vue.component('report-header', require('@/js/views/ReportHeader.vue').default);
    Vue.component('report-form', require('@/js/views/ReportForm.vue').default);

    export default {
/*
        props: [
            'storeData'
        ],*/

        data() {
            return {
                reportData: [],
                isLoading: true,
                store: {},
                currentStepIndex:0,
                showStepIcon:false,
                resellerTypeNames : [
                    "Petit revendeur",
                    "Moyen revendeur",
                    "Grand revendeur",
                    "Hybrid revendeur"
                ],
                stepNames : [
                    {name:"Frigo",icon:'icon-frigo'},
                    {name:"Télévision",icon:'icon-tv'},
                    {name:"Cuisiniere",icon:'icon-four'},
                    {name:"Machine à laver",icon:'icon-mal'}
                ]
            }
        },

        mounted() {
            //this.showStepIcon = true;
            // load stored Store data from vuex
            if (this.$store.state.storeData) {
                this.store = this.$store.state.storeData;
            }

            // reset  header photo
            this.$store.commit('saveStepImage', 'step1');

            this.isLoading = false;
        },

        components: {
            FormWizard,
            TabContent,
            WizardStep
        },

        methods: {

            onChange(prevIndex, nextIndex){

                if (nextIndex >= 0 && nextIndex < 4){

                    this.currentStepIndex  = nextIndex;

                    let $this = this;
                    let options = {
                        container: isMobile? 'body' : '.screen' ,
                        easing: 'ease-in',
                        offset: -60,
                        force: true,
                        cancelable: true,
                        onStart: function(element) {
                            // scrolling started
                            $this.isLoading = true;
                        },
                        onDone: function(element) {
                            // scrolling is done

                        },
                        onCancel: function() {
                            // scrolling has been interrupted
                            $this.isLoading = false;
                        },
                        x: false,
                        y: true
                    };

                    this.showStepIcon = true;
                    this.$scrollTo('main.app-container', 800 , options);

                    setTimeout(() => {
                        this.showStepIcon = false
                        this.isLoading = false;
                    }, 1500);
                }
            },

            onValidate(isValid, tabIndex) {

                if (isValid) {

                    let index = tabIndex + 1
                    let stepName = "step" + index;
                    let model = this.$refs[stepName].$refs['reportForm'].model;
                    this.reportData[tabIndex] = model;



                    // update header photo
                    this.$store.commit('saveStepImage', `step${index+1}`);

                }else{

                    Message.closeAll();
                    this.$message({
                        showClose: true,
                        message: 'Merci de vérifier que tous les champs sont bien renseignés!',
                        type: 'warning'
                    });
                }
            },

            // BUGFIX: ElementUi validation event issue, not firing callback  after validation
            validateForm(stepName) {
                let invalidFields = [];
                let stepForm = this.$refs[stepName].$refs['reportForm'];
                let valid = true;
                stepForm.fields.forEach(function (field) {
                    field.validate('', function (message, field) {
                        if (message) {
                            valid = false;
                        }
                        invalidFields.push(field);
                    });
                });
                return valid;
            },

            onComplete() {

                // save report data to local vuex
                this.$store.commit('saveDraft', this.reportData);

                // then go to the next route: /upload
                this.$router.push({
                    name:"upload"
                });
            }
        }
    }
</script>


<style lang="scss">

    /*********************************************
      App Wizard
    *********************************************/

    .step-overlay{

        position: fixed;
        z-index: 100000000000000020000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        vertical-align: middle;

        .step-num{
            position: relative;
            top: 50%;
            width: 120px;
            height: 120px;
            color: #364143;
            background-color: yellow;
            margin: -50px auto 0;
            font-size: 3em;
            line-height: 110px;
            border-radius: 100%;
            font-weight: bold;
            font-family: arial;
            border: 5px solid white;
            box-shadow: 0 5px 14px 1px #dc2e35;

            .step-icon{

                width: 110px !important;
                height: 110px !important;
                font-size: 24px;
                background-repeat: no-repeat;
                background-position: center center;


                    &.icon-frigo{
                        background-image: url('../../img/icon-frigo.png');
                    }
                    &.icon-tv{
                        background-image: url('../../img/icon-tv.png');
                    }
                    &.icon-four{
                        background-image: url('../../img/icon-four.png');
                    }
                    &.icon-mal{
                        background-image: url('../../img/icon-mal.png');
                    }

            }

            .step-title{
                font-size: 24px;
                font-weight: lighter;
                text-transform: uppercase;
                line-height: 100%;
                margin-top: 15px;
                width: 230px;
                margin-left: -60px;
                text-align: center;
            }
        }
    }

    .vue-form-wizard{

        .wizard-progress-bar {
            background-color: #364143 !important;
        }

        .wizard-icon-circle {
            background-color: #364143 !important;
            border: 0 none !important;

            .wizard-icon-container {
                border-radius: 100% !important;
            }
        }


    }

    .wizard-navigation{

        .wizard-icon {

            width: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            margin-top: -30px;

            &.icon-frigo {
                background-image: url('../../img/icon-frigo.png');
            }

            &.icon-tv {
                background-image: url('../../img/icon-tv.png');
            }

            &.icon-four {
                background-image: url('../../img/icon-four.png');
            }

            &.icon-mal {
                background-image: url('../../img/icon-mal.png');
            }
        }

        .stepTitle {
            color: #7b8283;
            z-index: 1;
            margin-top: -40px;
            text-transform: uppercase;
            line-height: 16px;
            max-width: 80px;
        }

        .active{
            .wizard-icon-circle {
                background-color: white!important;
            }
        }

    }

    .vue-form-wizard{
        &.lg{
            .wizard-icon-circle {
                width: 120px !important;
                height: 120px !important;
                font-size: 28px;
            }
            .wizard-navigation{
                .wizard-progress-with-circle {
                    top: 60px !important;
                }
            }
        }
        .wizard-card-footer {
            padding: 0 !important;
        }
    }



    .sheet {
        margin-top: -185px;
        min-height: 200px;
        background-color: #303d41;
        color: white;

        h4 {
            color: white;
            font-size: 1.3em;
            font-weight: bolder;
            display: inline-block;
        }
        span {
            font-size: 1.3em;
            display: block;
        }
        strong {
            color: white;
        }
    }

    .heading-sheet {
        color: #303d41;
    }
    .sheet h4::after, .heading-sheet h4::after {
        content: "";
        height: 4px;
        display: block;
        background-color: #dc2e35;
        margin-top: 15px;
    }

    /*********************************************
    Media Screen
    *********************************************/

    @media (max-width: 448px) {

        .sheet {
            margin-top: 0;
            span {
                font-size: 1em;
            }
        }

    }

    @media (min-width: 576px) {
        .sheet {
            margin-top: -100px!important;

        }
        .app-container {
            header{
                .img-pattern {
                    height: 175px;
                }
            }
        }
    }




</style>
