<template>
    <div style="z-index:1" class="col-12 text-right mt-0 mt-sm-2 ">
        <el-button plain size="mini" @click.prevent="$auth.logout()" class="logout-btn">
            <i class="fas fa-power-off"></i>
            Déconnexion
        </el-button>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                isLoading: true,
            }
        },

        mounted() {
            this.isLoading = false
        }
    }

</script>

<style lang="scss">

    /*********************************************
       Styles
   *********************************************/

    .logout-btn {
        border: 1px #e5e5e5 solid!important;
        background-color: white!important;
    }

</style>
