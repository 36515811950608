/*

* */

import Vue from 'vue';
import VueRouter from 'vue-router';


import Home from '@/js/components/Home';
import Login from '@/js/components/Login';
import Report from '@/js/components/Report';
import Upload from '@/js/components/Upload';

Vue.use(VueRouter);

const guard = async (to, from, next) => {
    if (Vue.prototype.$auth.check()){
        next('/home');
    }else{
        next();
    }
};


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path:'/',
            name:'index',
            component:Home,
            meta: { requiresAuth: true },
            beforeEnter: guard, // Using guard before entering the route
        },
        {
            path:'/login',
            name:'login',
            component:Login,
            meta: { requiresAuth: false },
            beforeEnter: guard, // Using guard before entering the route
        },
        {
            path:'/home',
            name:'home',
            component:Home,
            meta: { requiresAuth: true },
        },
        {
            path:'/report',
            name:'report',
            component:Report,
            props:true,
            meta: { requiresAuth: true }
        },
        {
            path:'/upload',
            name:'upload',
            component:Upload,
            props: true,
            meta: { requiresAuth: true }
        },
    ]
});

router.beforeEach((to, from, next) => {

    if(to.matched.some(record => record.meta.requiresAuth)) {

        if (Vue.prototype.$auth.check()){

            next();

        }else{

            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            });
        }

    } else {

        next();
    }
})

export default router;
