<template>

    <div class="bg-color bg-pattern h-100">
        <!-- Start: SplashScreen -->
        <div class="container h-100" id="splashscreen">
            <div class="row align-items-center h-100">
                <div class="col-12 mx-auto text-center" @click="">
                    <img src="images/logo.png" alt="Siera logo" class="img-fluid loading-logo">
                </div>
            </div>
        </div><!-- End: SplashScreen-->
    </div>

</template>


<script>
    export default {
        mounted() {
            console.log('SplashScreen mounted.')
        }
    }
</script>

