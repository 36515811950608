<template>



    <div class="h-100">

        <el-dialog title="Aperçu de l'image":visible.sync="dialogVisible"  :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" :alt="dialogImageUrl">
        </el-dialog>

        <!-- Start: Upload Photos -->
        <section class="app-container" v-loading="isLoading">

            <upload-header></upload-header>

            <nav class="nav">
                <div class="container">
                    <form-wizard
                        class="final-step"
                        title=""
                        subtitle=""
                        color=""
                        shape="circle"
                        back-button-text="Précédent"
                        next-button-text="Suivant"
                        finish-button-text="Valider"
                        step-size="lg"
                        :validateOnBack="false"
                    >
                        <wizard-step
                            slot-scope="props"
                            slot="step"
                            :tab="props.tab"
                        >
                        </wizard-step>

                        <tab-content title="Frigo" icon="icon-frigo"></tab-content>

                        <tab-content title="Télévision" icon="icon-tv"></tab-content>

                        <tab-content title="Cuisiniere" icon="icon-four"></tab-content>

                        <tab-content title="Machine à laver" icon="icon-mal"></tab-content>

                        <template slot="footer" slot-scope="props">
                            <div class="text-center">
                                <div id="total-scoring" :class="{ 'd-none' : !$auth.user().is_admin }" >Scoring : {{score}} %</div>
                                &nbsp;
                            </div>
                        </template>

                    </form-wizard>


                </div>
            </nav>

            <main id="app-content" class="container-fluid h-100 app-container py-3 py-sm-0">
                <div class="container">
                    <!-- Start: App Upload form -->
                    <div class="wizard-tab-content py-4">

                        <div class="form-section">
                            <el-upload
                                :on-preview="onFilePreview"
                                :on-change="onFileChange"
                                :before-remove="onBeforeFileRemove"
                                :on-remove="onFileRemove"
                                :on-exceed="ofFileExceed"

                                :limit="20"

                                :file-list="fileList"
                                class="upload-photos"
                                :action="`${axios.defaults.baseURL}/upload/photo`"
                                :headers = "jwtHeaders"
                                :with-credentials="true"

                                :auto-upload="false"

                                ref="uploader"


                            >
                                <el-button size="small" type="primary" class="w-100 d-block">Ajoutez les photos</el-button>
                                <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div>
                            </el-upload>



                        </div>

                        <div class="row">
                            <div class="offset-0 col-12  text-center offset-sm-0 col-sm-12 text-sm-right">
                                <el-button type="info" class="mb-3" @click="saveDraftWithImages()">Enregistrer dans le brouillon</el-button>
                                <el-button type="info" class="mb-3" @click="submitForm()">Enregistrer et valider</el-button>
                            </div>
                        </div>

                    </div>
                    <!-- End: App Upload Form -->
                </div>
            </main>
        </section>
        <!-- End:  Upload Photos -->
    </div>
</template>

<script>

    import Vue from 'vue';

    import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    Vue.component('upload-header', require('@/js/views/UploadHeader.vue').default);

    export default {

        data() {
            return {
                score: '0 %',
                isLoading: true,
                fileList:[],
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                jwtHeaders:{},
                reportData:[],
                reportId:null,
                isValidForm:false

            }
        },

        mounted() {

            // check if is a draft

            if (this.$store.state.draftId){

                // if is a draft try to load images list

                axios
                    .get('/photos/' + this.$store.state.draftId)

                    .then( response => {
                        this.fileList = response.data.data;
                    })

                    .catch( error => {
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });
                    })

                    .finally(() =>{
                        this.isLoading=false;
                    });

            }


            // setup JWT header to authorize upload
            this.jwtHeaders = {
                Authorization: 'Bearer ' + this.$auth.token()
            };

            // load saved report from local vuex
            if (this.$store.state.formData){
                this.reportData = this.$store.state.formData;

                // calculate overall score median
                this.score = this.calcScore(this.reportData);

                this.isLoading = false;

            } else{

                // if no data, reset and return to the home screen
                this.$alert('Échec du chargement. Merci de réessayer plus tard!', '', {
                    confirmButtonText: 'OK',
                    type: 'error',
                    center: true
                });

                this.$store.commit('clearReport', this.reportData);
                this.$store.commit('clearProductList', this.reportData);

                this.$router.push('home');

            }
        },

        components: {
            FormWizard,
            TabContent,
            WizardStep
        },

        methods:{

            _submitReport() {


                axios

                    .post('/report/store', {
                        report: this.reportData,
                        store: this.$store.state.storeData,
                        draft:this.$store.state.draftId,
                        valid:this.isValidForm
                    })

                    .then(response => {

                        // get the current reportID
                        this.reportId = response.data.reportId;

                        this.$alert(response.data.msg, '', {
                            confirmButtonText: 'OK',
                            type: response.data.status,
                            center: true

                        }).then(()=>{

                            // upload photo for the current reportID

                            if(this.reportId){

                                this.jwtHeaders['Report-Id'] =  this.reportId;
                                this._uploadPhotos();

                            }else{

                                this.$alert("Erreur lors de la tentative d'enregistrement du rapport, veuillez réessayer!", '', {
                                    confirmButtonText: 'OK',
                                    type: 'error',
                                    center: true
                                });
                            }


                        }).finally(()=>{
                            this.isLoading = false;
                            this.$store.commit('clearAll');
                            this.$router.push('home');
                        });

                    })

                    .catch(error => {

                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        }) .then(()=>{
                            this.$store.commit('clearAll');
                            this.$router.push('home');
                        });

                    })

                    .finally(() => {
                        this.isLoading = false;
                    });

            },


            _uploadPhotos(){
                if (this.$refs.uploader){
                    this.$refs.uploader.submit();
                }

            },

            onFilePreview(file){
                let url = file.response? file.response.url : file.url;
                if (url){
                    this.dialogImageUrl = url;
                    this.dialogVisible = true;
                }
            },

            onFileChange(file, fileList) {

                this.fileList = fileList;

            },

            ofFileExceed(files, fileList) {
                this.$message.warning(`The limit is 20, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
            },

            onFileRemove(file, fileList){

                axios
                    .post('/delete/photo', {
                        photo_id:file.response? file.response.id: file.id
                    })

                    .then( response => {

                    })

                    .catch( error => {
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });
                    })

                    .finally(() =>{
                        this.isLoading=false;
                    });


            },

            onBeforeFileRemove(file, fileList){
                return this.$confirm(`Annuler le transfert de ${ file.name } ?`, '', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Annuler',
                    type: 'warning'
                });
            },

            calcScore(form){
                // calculating scoring
                let score = 0;
                for (let s = 0 ; s < 4; s++) {
                    score += form[s].overall_score;
                }
                return Math.ceil(score / 4 );
            },

            saveDraftWithImages(){
                //submit form as draft
                this.isLoading = true;
                this.isValidForm = false;
                this._submitReport();
            },

            submitForm(){
                //submit form
                this.isLoading = true;
                this.isValidForm = true;
                this._submitReport();
                //
                // foreace update
                /*axios.post('/report/store', {
                    report: this.reportData,
                    store: this.$store.state.storeData,
                    draft:this.$store.state.draftId,
                    valid:this.isValidForm
                });*/
            }

        }

    }
</script>

<style lang="scss">

    /*********************************************
        Styles
    *********************************************/

    .wizard-tab-content {
        .form-section {
            padding: 15px;
            background-color: white;
            border-radius: 10px;
            margin-bottom: 20px;
        }
    }

    .upload-photos {
        .el-upload {
            display: block !important;
        }
        .el-button {
            background-color: #dc2e35 !important;
            border-color: red;
            animation: btnHighlight 3s infinite;
        }
    }

    .el-icon-close-tip{
        visibility: hidden!important;
    }

    .final-step {
        .active{
            .wizard-icon-circle {
                background-color: #364143!important
            }
        }
    }

</style>
