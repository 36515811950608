<template>
    <div class="marvel-device ipad silver" v-if="!isMobile">
        <div class="camera"></div>
        <div class="screen">
            <!-- Content goes here -->
            <div class="h-100">

                <template v-if="isLoading">
                    <splash-screen></splash-screen>
                </template>

                <template v-else>
                    <router-view></router-view>
                </template>

            </div>

            <!-- Content ends here -->
        </div>
        <div class="home"></div>
    </div>

    <div class="h-100" v-else>
        <template v-if="isLoading">
            <splash-screen></splash-screen>
        </template>

        <template v-else>
            <router-view></router-view>
        </template>

    </div>


</template>

<script>

    import Vue from 'vue';
    import {isMobile} from 'mobile-device-detect';

    Vue.component('splash-screen', require('@/js/views/SplashScreen.vue').default);

    export default {

        data() {
            return {
                isMobile: isMobile,
                isLoading: true,
            }
        },

        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000);

        }
    }
</script>


<style lang="scss">
    .marvel-device {
        margin: 30px auto;
        display: block;
    }

    .marvel-device .screen {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    body {
        background-color: #303d41;
    }
</style>


