import { render, staticRenderFns } from "./HeaderLogout.vue?vue&type=template&id=a1be155c&"
import script from "./HeaderLogout.vue?vue&type=script&lang=js&"
export * from "./HeaderLogout.vue?vue&type=script&lang=js&"
import style0 from "./HeaderLogout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports