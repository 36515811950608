<template>
    <header class="container-fluid px-0">
        <div class="w-100 position-absolute">
            <header-logout></header-logout>
        </div>
        <div class="container py-0 px-sm-0">
            <div class="row">
                <div class="col-12 col-sm-4">
                   <header-logo></header-logo>
                </div>
                <div class="col-12 col-sm-8 ">
                    <div class="row align-items-center h-100">
                        <div class="col-12 px-0" v-loading="isLoading">
                            <div class="notices px-3 py-2 mr-0 mr-sm-3" >
                                <h4 class="py-1">
                                    <span class="bell fas fa-bell"></span>
                                    {{draftMsg}}
                                </h4>
                                <home-notices ref="notices" :reports="draftList"></home-notices>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

    import Vue from 'vue';

    Vue.component('header-logo', require('@/js/views/HeaderLogo.vue').default);
    Vue.component('header-logout', require('@/js/views/HeaderLogout.vue').default);
    Vue.component('home-notices', require('@/js/views/HomeNotices.vue').default);

    export default {

        data() {

            return {

                isLoading: true,

                draftMsg: "Vous n'avez pas de rapport à envoyer",

                draftCount:0,

                draftList:[],
            }
        },

        mounted() {

            this.isLoading = true;

            // load drafts from database

            axios

                .get('/draft')

                .then( response => {

                    this.draftList = response.data.data;
                    this.draftCount = this.draftList.length;
                    if (this.draftCount > 0 ) {
                        this.draftMsg = `Vous avez ${this.draftCount} rapports non envoyés!`;
                    }else{
                        // if not draft we clear all saved data
                        // to prevent sending wrong params to server
                        this.$store.commit('clearAll');
                    }
                })

                .catch( error => (
                    this.$alert(error, '', {
                        confirmButtonText: 'OK',
                        type: 'error',
                        center: true
                    })
                ))

                .finally(() =>{
                    this.isLoading = false;
                    this.draftCount = this.draftList.length
                });
        }
    }
</script>

<style lang="scss">

    /*********************************************
        Styles
    *********************************************/



</style>
