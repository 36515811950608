<template>
    <div class="h-100" style="background-color:#dc2e35" v-loading="isLoading" >
    <!-- Start: App Home Screen -->
    <section class="app-container">

        <!-- Start:  Home Header -->
        <home-header></home-header>
        <!-- End:  Home Header -->

        <!-- Start:  Home Nav -->
        <nav class="nav">
            <div class="container">
                <a class="nav-link active" href="#">Bienvenue {{$auth.user().name}}</a>
            </div>
        </nav>
        <!-- End:  Home Nav -->

        <!-- Start:  Home Content -->
        <main id="app-content" class="container-fluid h-100 app-container pt-3 pb-3 pt-sm-0 py-sm-5"  >
            <div class="container">
                    <!-- Start: Filter Icons -->
                    <div class="row ">
                        <div class="col-12 col-sm-6 my-1 my-sm-5 px-1 px-sm-2">

                            <!-- Start: Moyen Revendeur -->
                            <a href="javascript:void(0)" class="reseller-card" @click="selectReseller(2)" :class="{ active : resellerType == 2 }">
                                <div class="icon-wrap py-4">
                                    <div class="text-center icon">
                                        <img src="images/shop-icon-md.png">
                                    </div>
                                </div>
                                <div class="icon-title text-center py-3 px-4">
                                    <h5 class="text-uppercase font-weight-bold">Moyen <span class="font-weight-light">revendeur</span></h5>
                                </div>
                            </a>
                            <!-- End: Moyen Revendeur -->

                        </div>
                        <div class="col-12 col-sm-6 my-1 my-sm-5 px-1 px-sm-2">

                            <!-- Start: Grand Revendeur -->
                            <a href="javascript:void(0)" class="reseller-card" @click="selectReseller(3)" :class="{ active : resellerType == 3 }">
                                <div class="icon-wrap py-4">
                                    <div class="text-center icon">
                                        <img src="images/shop-icon-lg.png">
                                    </div>
                                </div>
                                <div class="icon-title text-center py-3 px-4">
                                    <h5 class="text-uppercase font-weight-bold">Grand <span class="font-weight-light">revendeur</span></h5>
                                </div>
                            </a>
                            <!-- End: Grand Revendeur -->

                        </div>
                    </div>
                    <!-- End: Filter Icons -->

                    <!-- Start: Filter Select -->
                    <div class="my-3">

                        <!-- Start: Choisir la ville -->
                        <el-row v-if="citiesList.length > 0 && resellerType">
                            <el-col :span="20" :offset="2" class="mb-4">
                                <el-select
                                    placeholder="Choisir la ville" size="large"
                                    class="w-100"
                                    v-model="city"
                                    clearable
                                    filterable

                                    @change="onSelectCity()"
                                >

                                        <el-option
                                            v-for="item in citiesList"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.id">
                                        </el-option>

                                </el-select>
                            </el-col>
                        </el-row>
                        <!-- End: Choisir la ville -->

                        <!-- Start: Magasin -->
                        <el-row  v-if="storesList.length > 0">
                            <el-col :span="20" :offset="2" class="mb-4">

                                <el-select
                                        placeholder="Magasin"
                                        size="large"
                                        class="w-100"
                                        clearable
                                        filterable
                                        v-model="store"

                                        @change="onSelectStore()"
                                >
                                    <el-option
                                        v-for="item in storesList"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <!-- End: Magasin -->


                        <!-- Start: Valider -->
                        <el-row>
                            <el-col :xs="{span:24, offset:0}" :sm="{span:20, offset:2}" :md="{span:20, offset:2}" class="text-right">
                                <el-button type="info" @click="submit()" :disabled="!store">Valider</el-button>
                            </el-col>
                        </el-row>
                        <!-- End: Valider -->

                    </div>
                    <!-- End: Filter Select -->


            </div>
        </main>
    </section>
    <!-- End: App Home Screen -->
    </div>
</template>

<script>
    import Vue from 'vue';

    Vue.component('home-header', require('@/js/views/HomeHeader.vue').default);

    export default {

        data() {
            return {
                isLoading: true,
                resellerType: null,
                citiesList:[],
                city:null,
                storesList:[],
                store:null,
                storeData:{}
            }
        },

        mounted() {

            this.isLoading = false;
            //this.downloadExcelFile('http://sieraapp.test/api/report/download/58/pdf');
        },

        methods: {


            /*_forceFileDownload(response){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.pdf') //or any other extension
                document.body.appendChild(link)
                link.click()
            },*/


            /*downloadExcelFile(url){

                this.isLoading = true;

                axios

                    ({
                        method: 'get',
                        url: url,
                        responseType: 'arraybuffer'
                    })

                    .then(response => {
                        this._forceFileDownload(response)

                    })

                    .catch(error => {
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });
                    })

                    .finally(() =>{
                        this.isLoading = false;
                    });



            },*/

            _loadProducts(){

                // check for store products

                this.isLoading = true;



                axios

                    .get('/products/' + this.storeData.resellerType )

                    .then( response => {

                        this.$store.commit('saveProductList', response.data.data);



                        this.$router.push({
                            name:"report"
                            /*
                            params: {
                                storeData: this.storeData
                            }*/
                        });


                    })

                    .catch( error => {
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });
                    })

                    .finally(() =>{
                        this.isLoading=false;
                    });

            },

            _resetCities(){
              this.citiesList = [];
              this.city = null;
            },

            _resetStores(){
                this.storesList = [];
                this.store = null;
            },

            _updateCities(){

                this.isLoading = true;

                axios

                    .get(`/stores/${this.resellerType}/cities`)

                    .then( response => {

                        this.citiesList = response.data.data;

                        if (!this.citiesList.length){
                            this._resetCities();
                            this._resetStores();

                        }
                    })

                    .catch( error => (
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        })
                    ))

                    .finally(() =>{
                        this.isLoading = false;
                    });

            },

            _updateStores(){

                this.isLoading = true;

                axios

                    .get(`/stores/${this.resellerType}/${this.city}`)

                    .then( response => {
                        this.storesList = response.data.data;
                    })

                    .catch( error => {
                        this.$alert(error, '', {
                            confirmButtonText: 'OK',
                            type: 'error',
                            center: true
                        });
                    })

                    .finally(() =>{
                        this.isLoading = false;
                    });
            },

            submit() {

                this.isLoading = true;



                if (this.store){


                    let storeName = this.storesList.find( x => x.id === this.store).label;
                    let cityName = this.citiesList.find( x => x.id === this.city).label;

                    this.storeData = {

                        resellerType: this.resellerType,
                        city: this.city,
                        store: this.store,
                        storeName:storeName,
                        cityName:cityName

                    };

                    this.$store.commit('saveStore', this.storeData);

                    this._loadProducts();

                }else{

                    this.$alert('Merci de sélectionner un magasin!', '', {
                        confirmButtonText: 'OK',
                        type: 'error',
                        center: true
                    });

                    this.isLoading = false;

                }
            },

            selectReseller(type) {

                this.resellerType = type;

                this._resetCities();
                this._resetStores();
                this._updateCities();
            },

            onSelectCity(){
                this._resetStores();
                this._updateStores();
            },

            onSelectStore(){



            }
        }
    }
</script>

<style lang="scss">

    /*********************************************
        App Home
    *********************************************/

    .el-select {
        .el-input__inner {
            height: 60px;
            line-height: 60px;
            border-radius: 30px;
        }
    }

    .el-button--info {
        background-color: #364143 !important;
        height: 50px;
        font-size: 20px;
        min-width: 230px;
        border-radius: 30px !important;
        padding: 0;
        cursor: pointer;
    }

    .reseller-card{

        color:white;
        text-decoration: none;

        &.active{
            color: white;
            border: 2px solid #ffc107;
            text-decoration: none;
            display: block;
            box-shadow: 0px 2px 3px 0px #7b0404;
            h5{
                color:yellow;
            }

            .icon{
                background-color:yellow;

            }
            .icon-wrap{
                border-radius: 0;
            }
            .icon-title{
                border-radius: 0;
            }
        }

        &:hover{
            color:white;
            text-decoration: none;
        }

        .icon-wrap{
            line-height: 135px;
            background-color: white;
            padding: 15px;
            border-radius: 5px 5px 0 0;
        }

        .icon{
            border: 1px solid gray;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            border-radius: 100%;
            line-height: 100px;

            img{

            }
        }

        .icon-title{
            background-color:#364143;
            border-radius: 0 0 5px 5px;

            h5{
                span{
                    display: block;
                }
            }
        }
    }

    .app-container header{
        min-height: 170px!important;
    }

    /*********************************************
        Media Screen
    *********************************************/



    @media (max-width: 448px) {
        .app-container header .notices h4 {
            font-size: 1em;
        }
    }

</style>
